export function formatDate(date) {
  const months = [
    "STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"
  ];

  const day = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export function formatDateTime(dateTime) {
  const months = [
    "STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"
  ];
  const day = String(dateTime.getDate()).padStart(2, "0");
  const month = months[dateTime.getMonth()];
  const year = dateTime.getFullYear();
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
}

export function scrollToElement(className) {
  const element = document.getElementsByClassName(className)[0];
  if (!element) return;
  element.scrollIntoView({ behavior: "smooth" });
}

export function parseNameAndSurname(name) {
  const names = name.split("___");
  const surname = names[names.length - 1];
  const nameWithoutSurname = names[0]
  return nameWithoutSurname + " " + surname;
}

export function parseDayName(name) {
  switch (name) {
    case "PON":
      return "Poniedziałek";
    case "WT":
      return "Wtorek";
    case "ŚR":
      return "Środa";
    case "CZW":
      return "Czwartek";
    case "PT":
      return "Piątek";
    case "SOB":
      return "Sobota";
    default:
      return "Poniedziałek";
  }
}

export function getDayValue(name) {
  switch (name) {
    case "PON":
    case "Poniedziałek":
      return 0;
    case "Wtorek":
    case "WT":
      return 1;
    case "Środa":
    case "ŚR":
      return 2;
    case "Czwartek":
    case "CZW":
      return 3;
    case "Piątek":
    case "PT":
      return 4;
    case "Sobota":
    case "SOB":
      return 5;
    default:
      return 0;
  }
}

// accept array of classes and turn it into array of items that can be passed to calendar
export function parseClassesToCalendar(classes) {

  const calendarItems = [];
  classes.forEach((classItem) => {
    const { title, eventDates, instructor } = classItem;
    eventDates.forEach((eventDate) => {
      const { start, duration, eventDay } = eventDate;
      const parsedStart = start / 60

      const calendarItem = {
        title: title,
        start: parsedStart,
        duration: duration,
        eventDay: eventDay,
        instructor: parseNameAndSurname(instructor[0]),
        slug: classItem.slug,
      }

      calendarItems.push(calendarItem);
    });
  });
  calendarItems.sort((a, b) => a.start - b.start);
  return calendarItems;
}

export function parseNumberToHour(number) {
  const hour = Math.floor(number);
  const minutes = number % 1 === 0.5 ? "30" : "00";
  return `${hour}:${minutes}`;
}