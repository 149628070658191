import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import "./contact-form.sass";
import { navigate } from "gatsby-link";
import { onlyUnique, parseNumberToHour } from "../utils/utils";


const ContactForm = ({ data }) => {
  const { eventDates, title } = data.markdownRemark.frontmatter;

  const [inputs, setInputs] = React.useState({
    class: title
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate("/"))
      .catch((error) => alert(error));
  };

  return (

    <Layout>
      <section className="section">
        <h1>FORMULARZ KONTAKTOWY</h1>
        <div className="contact-form-wrapper">
          <h5 className="h5-black">Żeby zapisać się na zajęcia wypełnij poniszy formularz</h5>
          <p>Po jego otrzymaniu sprawdzimy dostępność wolnych miejsc w podanych terminach i odezwiemy się do Ciebie.</p>
          <form name="contact" method="post" netlify="true" netlify-honeypot="bot-field" onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="class" value={title} />
            <label>Imię i nazwisko <input required type="text" name="name" id="name" onChange={handleChange} /></label>
            <label>Email <input required type="email" name="email" id="email" onChange={handleChange} /></label>
            <label>Numer telefonu <input type="number" name="number" id="number" onChange={handleChange} /></label>
            <label>Twoja wiadomość <textarea rows="6" name="message" id="message" onChange={handleChange} /></label>
            <label>WYBIERZ ZAJĘCIA
              <fieldset id="day" onChange={handleChange}>
                {
                  eventDates.map((date, index) => {
                    const hour = parseNumberToHour(date.start / 60);
                    const end = parseNumberToHour(date.start / 60 + date.duration);
                    return (
                      <label key={index} className="checkbox-container"><input required type="radio" name="day" value={`${date.eventDay} " " | " " ${hour} - ${end}`} />{`${date.eventDay}  |  ${hour} - ${end}`}</label>
                    )
                  }
                  )
                }
              </fieldset>
            </label>
            <button type="submit">Wyślij</button>
          </form>
        </div>
      </section>
      <hr className="hr"></hr>
    </Layout>
  )
}

export default ContactForm;

//get class by id passed from gatsby-node.js
export const pageQuery = graphql`
query MyQuery($id: String!) {
  markdownRemark(id: {eq: $id}) {
    frontmatter {
      title
      templateKey
      eventDates {
        eventDay
        start
        duration
      }
    }
  }
}
`

